<template>
<div class="PcAnchors is_pc">
  <p v-if="menus.length" class="catch">{{ isTop ?  '＼最優秀賞をササッとチェック／' : '＼受賞商品をササッとチェック／' }}</p>
  <ul v-if="menus.length">
    <template v-for="(menu,index) in menus" :key="index">
      <li class="anchor"
          v-bind:class="menu.id"
          v-bind:data-anchor="menu.id"
          v-bind:data-name="menu.name"
          v-bind:data-parent="'pcAnchor'"
          v-on:click="jumpToTarget($event)">
        {{ menu.name }}
        <span class="allowBg"><span class="circle"></span></span>
      </li>
    </template>
  </ul>
</div>

<section id="stickyAnchorNav">
  <div class="stickyBackground"></div>
  <div class="inner">
    <ul v-if="menus.length" class="stickyAnchorNavContainer" v-on:click="openNavigation(menus[0].name)">
      <span class="bgHighLightLayer"></span>
      <li class="anchor, firstElm"
          v-bind:class="menus[0].id"
          v-bind:data-anchor="menus[0].id"
          v-bind:data-name="menus[0].name"
          v-bind:data-parent="'sticky'"
          v-on:click="jumpToTarget($event, 0)">
        <span class="flexibleText stickyTextElm">{{ currentAreaName }}</span>
        <img class="stickyArrow" src="@/assets/images/Icon_arrow.svg" alt="">
      </li>
      <li class="secondElm">
        <ul class="hiddenItems">
          <template v-for="(menu,index) in menus" :key="index">
            <li v-if="index !== 0" class="anchor"
                v-bind:class="menu.id"
                v-bind:data-anchor="menu.id"
                v-bind:data-name="menu.name"
                v-bind:data-parent="'sticky'"
                v-on:click="jumpToTarget($event, index)">
              <span class="hiddenText stickyTextElm">{{ menu.name }}</span>
            </li>
          </template>
        </ul>
      </li>
    </ul>
  </div>
</section>
</template>

<script>
import util from '@/jsUtil/util'
import { TimelineMax,Expo } from 'gsap'
import jump from 'jump.js'
import {debounce} from "throttle-debounce";
export default {
  name: 'StickyNav',

  props: {
    menus: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isTop: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      events: {},
      currentAreaIndex: 0,
      currentAreaName: "",
      cacheWindowWidth: 0,
      timeoutId: null,
      targetPositions: [],
      isOpen: false,
      navProggress: false,
    }
  },

  mounted () {
    setTimeout(()=>{
      if(this.menus.length) {
        this.currentAreaName = this.menus[0].name;
        document.querySelector("#stickyAnchorNav").style.visibility = "visible";
      }
      this.setScrollEvents();
      this.setWindowResizeEvents();
      this.restoreAnchorTargetPosition();
      this.upDateCurerntAreaIndexByPosition();
    },1000);
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.events["scrollEvents"]);
    window.removeEventListener("resize", this.events["resizeEvents"])
  },

  methods: {
    openNavigation() {
      if (!this.menus.length) return;
      if (this.navProggress) return;

      const secondElm = document.querySelector(".secondElm");
      const stickyAnchorNav = document.querySelector("#stickyAnchorNav");
      const hiddenItems = document.querySelector(".hiddenItems");

      if(!stickyAnchorNav.classList.contains("active")) {
        this.navProggress = true;
        this.currentAreaName = this.menus[0].name;
        util.addactive(stickyAnchorNav);
        let hiddenItemsHeight =  hiddenItems.offsetHeight;
        let tl = new TimelineMax({
          onComplete: () => {
            this.navProggress = false;
            this.calcBgHighLightLayer();
            tl.kill();
          }});
        tl.to(secondElm, 0.1, { height: hiddenItemsHeight});
      }
    },

    hideNavigation() {
      if (this.navProggress) return;

      const secondElm = document.querySelector(".secondElm");
      const stickyAnchorNav = document.querySelector("#stickyAnchorNav");

      if(stickyAnchorNav.classList.contains("active")) {
        this.navProggress = true;
        util.removeactive(stickyAnchorNav);
        let tl = new TimelineMax({
          onComplete: () => {
            const stickyTextElms = document.querySelectorAll(".stickyTextElm");
            this.calcBgHighLightLayer();
            setTimeout(()=>{
              this.navProggress = false;
              stickyTextElms.forEach((el)=>{
                util.removeactive(el, "hidden");
              });
            }, 300);
            setTimeout(()=>{
              window.addEventListener("scroll", this.events["scrollEvents"]);
            }, 1000);
            tl.kill();
          }});
        tl.to(secondElm, 0.1, { height: 0});
      }
    },

    setScrollEvents() {
      const debouncedChangeCurrentNameAnim = this.debounce((name) => this.changeCurrentNameAnim(name), 400);
      this.events["scrollEvents"] = () => {
        if(!this.menus.length) return;
        const currentScroll = util.getScrollTop();

        const windowheight = window.outerHeight;
        const isSpSize = window.outerWidth < 828;
        const coef = isSpSize ? 0.3 : 0.7;

        if(currentScroll + (windowheight * coef) >= this.targetPositions[this.currentAreaIndex + 1]) {
          this.currentAreaIndex += 1;
          debouncedChangeCurrentNameAnim(this.menus[this.currentAreaIndex].name);
        } else if(currentScroll + (windowheight * coef) <= this.targetPositions[this.currentAreaIndex]) {
          if (this.currentAreaIndex === 0) return;
          this.currentAreaIndex = Math.max(this.currentAreaIndex - 1,0);
          debouncedChangeCurrentNameAnim(this.menus[this.currentAreaIndex].name);
        }
      }
      window.addEventListener("scroll", this.events["scrollEvents"]);
    },

    upDateCurerntAreaIndexByPosition() {
      const findClosestIndex = (numbers, target) => {
        return numbers.reduce((closestIndex, currentNumber, currentIndex) => {
          return Math.abs(currentNumber - target) < Math.abs(numbers[closestIndex] - target) ? currentIndex : closestIndex;
        }, 0);
      }
      const currentScroll = util.getScrollTop();
      const nearestAeraIndex = findClosestIndex(this.targetPositions, currentScroll);
      if (this.currentAreaIndex !== nearestAeraIndex) {
        this.currentAreaIndex = findClosestIndex(this.targetPositions, currentScroll);
        this.currentAreaName = this.menus[this.currentAreaIndex].name;
      }
    },

    changeCurrentNameAnim(name) {
      const flexibleText = document.querySelector(".flexibleText");
      util.addactive(flexibleText, "hidden");
      setTimeout(()=>{
        this.currentAreaName = name;
        util.removeactive(flexibleText, "hidden");
      },200);
    },

    debounce(func, delay) {
      let timeoutId = null;
      return function(...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func.apply(this, args), delay);
      };
    },

    reloadAnchorTargetPosition(){
      return debounce(100, () => {
        const docWidth = document.body.clientWidth;
        if ( Math.abs(this.cacheWindowWidth - docWidth) > 30 ) {
          this.cacheWindowWidth = docWidth;
          this.restoreAnchorTargetPosition();
        }
      });
    },

    restoreAnchorTargetPosition(){
      const targetsEls = document.querySelectorAll(".anchorTarget");
      this.targetPositions = [];
      targetsEls.forEach((el,i) => {
        this.targetPositions.push(util.getoffsetTop(el));
      });
      setTimeout(()=>{
        this.calcBgHighLightLayer();
      },500);
    },

    calcBgHighLightLayer(){
      const bgHighLightLayer = document.querySelector(".bgHighLightLayer");
      const firstElm = document.querySelector(".firstElm");
      const topMargin = "0.187em";
      let firstElmHeight =  firstElm.offsetHeight;
      bgHighLightLayer.style.height = firstElmHeight + "px";
      const stickyAnchorNav = document.querySelector("#stickyAnchorNav");
      if(stickyAnchorNav.classList.contains("active")) {
        bgHighLightLayer.style.top = (firstElmHeight * this.currentAreaIndex) + "px";
      } else {
        bgHighLightLayer.style.top = topMargin;
      }
    },

    setWindowResizeEvents(){
      this.events["resizeEvents"] = this.reloadAnchorTargetPosition();
      window.addEventListener("resize",this.events["resizeEvents"]);
    },

    jumpToTarget(e, index){
      const dataParent = e.currentTarget.getAttribute('data-parent');
      const dataAnchor = e.currentTarget.getAttribute('data-anchor');
      const dataName = e.currentTarget.getAttribute('data-name');
      if (dataParent === "sticky") {
        const stickyAnchorNav = document.querySelector("#stickyAnchorNav");
        if(stickyAnchorNav.classList.contains("active")) {
          const targetEl = document.querySelector("." + dataAnchor + "ForAnchor");
          this.currentAreaName = dataName;
          if(targetEl) {
            const stickyTextElms = document.querySelectorAll(".stickyTextElm");
            stickyTextElms.forEach((el)=>{
              util.addactive(el, "hidden");
            });
            window.removeEventListener("scroll", this.events["scrollEvents"]);
            setTimeout(()=>{
              this.currentAreaName = dataName;
              this.currentAreaIndex = index;
              jump(targetEl,{ offset: -50, duration: 100 });
            },300);
            this.hideNavigation();
          }
        }
      } else {
        const targetEl = document.querySelector("." + dataAnchor + "ForAnchor");
        this.currentAreaName = dataName;
        if(targetEl) {
          jump(targetEl,{ offset: -20, duration: 100 });
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">

.PcAnchors {
  position: fixed;
  top: 150px;
  left: 40px;
  width: displaysize(240);
  z-index: 2000;
  &.active {
    .catch {
      visibility: visible;
      opacity: 1;
    }
    li {
      box-sizing: border-box;
      transform: translate(0, 0);
    }
  }
  .catch {
    display: inline-block;
    color: #FF8E91;
    font-size: displaysize(14);
    margin: 0 auto displaysize(15) auto;
    white-space: nowrap;
    letter-spacing: 0.01em;
    visibility: hidden;
    opacity: 0;
    @include transition_anim($delay-time: 0.5s, $duration-time: 0.5s, $propertys: (opacity));
  }
  li {
    width: 200px;
    background-color: #FFFFFF;
    position: relative;
    border-radius: 10px;
    padding: displaysize(8) displaysize(8);
    margin-bottom: displaysize(5);
    transform: translate(-200%, 0);
    font-size: displaysize(11);
    font-weight: bold;
    border: 1px solid #FF8E91;
    box-sizing: border-box;
    letter-spacing: 0.01em;
    color: #FF8E91;
    cursor: pointer;
    &.tomonite-special-award,&.department-of-expectations {
      border: none;
      color: #FFFFFF;
      background-color: #FF9900;
      .allowBg {
        .circle {
          background-color: #FFFFFF;
        }
        &:before {
          background-image: url("~@/assets/images/icon_arror_orange.png");
          background-repeat: no-repeat;
          background-size: 100% auto;
        }
      }
    }
    &:hover {
      .allowBg {
        width: displaysize(18);
        padding-top: displaysize(18);
        right: 15px;
      }
    }
    .allowBg {
      display: block;
      position: absolute;
      width: 0;
      padding-top: 0;
      right: 20px;
      overflow: hidden;
      top: 50%;
      transform: translate(0,-50%);
      @include transition_anim($duration-time: 1.0s, $propertys: (width,padding-top,right));
      .circle {
        display: block;
        position: absolute;
        width: 100%;
        padding-top: 100%;
        border-radius: 50%;
        left: 0;
        top: 0;
        z-index: 0;
        background-color: $fontPink;
        backface-visibility: hidden;
      }
      &:before {
        @include content_pseudo;
        width: 50%;
        height: 50%;
        background-size: 100% auto;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%) rotate(90deg);
        z-index: 100;
        background-image: url("~@/assets/images/icon_arrow_white.png");
        background-repeat: no-repeat;
      }
    }
    @for $i from 1 through 20 {
      &:nth-of-type(#{$i}) {
        $secound: #{0.2 + ($i * 0.03) - 0.1}s;
        @include transition_anim($delay-time: $secound, $duration-time: 0.5s, $propertys: (transform));
      }
    }
  }
  @include resizebywidth_lessthan($base_resize_size){
    display: none;
  }
}

 #stickyAnchorNav {
   // テスト用
   position: fixed;
   display: flex;
   z-index: 8000;
   width: 100vw;
   height: 100%;
   justify-content: center;
   align-items: end;
   top: 0;
   visibility: hidden;
   backface-visibility: hidden;
   pointer-events: none;
   will-change: transfrom;
   @include resizebywidth_morethan($base_resize_size + 1){
     display: none;
   }
   .inner {
     position: relative;
     display: flex;
     justify-content: center;
     align-items: center;
     overflow: hidden;
     margin-bottom: 4%;
     z-index: 1;
     transform: translate(0, 300%);
     @include transition_anim($duration-time: 0.6s);
     &.navShown {
       transform: translate(0,0);
     }
   }
   .stickyBackground {
     position: absolute;
     left: 0;
     top: 0;
     width: 100%;
     height: 100%;
     opacity: 0;
     pointer-events: none;
     background-color: rgba(0,0,0,0.2);
     @include transition_anim($delay-time: 0.1s, $duration-time: 0.1s);
   }
   &.active {
     .stickyBackground {
       opacity: 1;
       pointer-events: auto;
     }
     .stickyAnchorNavContainer {
       .firstElm {
         .stickyArrow {
           opacity: 0;
         }
       }
       .secondElm {
         .anchor {
           .hiddenText {
             opacity: 1;
           }
         }
       }
     }
   }
   &.hidden {
     transform: translate(0,30%);
   }
   .stickyAnchorNavContainer {
     display: flex;
     justify-content: center;
     align-items: end;
     flex-direction: column;
     overflow: hidden;
     padding: 0.187em;
     width: 100%;
     border-radius: 10px;
     background: #fff;
     border: 1px solid #FF8E91;
     box-sizing: border-box;
     box-shadow: 0 0 8px rgba(8,49,81,.2);
     pointer-events: auto;
     transform: translate(0,0);
     @include transition_anim($delay-time: 0.1s, $duration-time: 0.5s);
     .bgHighLightLayer {
       position: absolute;
       top: 0;
       left: 50%;
       transform: translate(-50%,0);
       will-change: top;
       width: calc(100% - 0.374em);
       border-radius: 4px;
       height: 0;
       background-color: #FFF9E3;
       z-index: 0;
       @include transition_anim($delay-time: 0.1s, $duration-time: 0.5s, $propertys: (top));
     }
     span {
       color: #FF8E91;
       font-weight: 500;
     }
     li {
       cursor: pointer;
       .stickyTextElm {
         &.hidden {
           opacity: 0;
         }
       }
     }
     .firstElm {
       position: relative;
       text-align: center;
       width: 70vw;
       max-width: 500px;
       justify-content: center;
       z-index: 100;
       .flexibleText {
         font-size: 2.8vw;
         display: inline-block;
         padding: 4% 0;
         white-space: nowrap;
         opacity: 1;
         @include transition_anim($duration-time: 0.5s);
         &.hidden {
           opacity: 0;
         }
       }
       .stickyArrow {
         position: absolute;
         top: 50%;
         right: 6%;
         transform: translate(0,-50%);
         width: percent_resize(18,350);
         opacity: 1;
         @include transition_anim($duration-time: 0.5s);
       }
     }
     .secondElm {
       text-align: center;
       width: 70vw;
       max-width: 500px;
       height: 0px;
       z-index: 100;
       .anchor {
         text-align: center;
         .hiddenText {
           font-size: 2.8vw;
           display: inline-block;
           padding: 4% 0;
           white-space: nowrap;
           opacity: 0;
           @include transition_anim($duration-time: 0.5s);
         }
       }
     }
   }

 }
</style>