import { Object3D } from 'three';
import AnimatedMeshLine from './AnimatedMeshLine';

export default class LineGenerator extends Object3D {
  constructor({ frequency = 0.1 } = {}, lineProps) {
    super();

    //線がupdate時に追加される確率
    this.frequency = frequency;
    //AnimatedMeshLineに渡す固定プロパティ
    this.lineStaticProps = lineProps;
    //線がupdate時に追加されるかどうかのフラグ(falseだと線が追加されず、いずれ0になる)
    this.isStarted = false;

    this.i = 0;
    //各線をコントロールするために線オブジェクトをためておく
    this.lines = [];
    //線のカウント
    this.nbrOfLines = -1;


    this.update = this.update.bind(this);
    this.start = this.start.bind(this);
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
  }


  /**
   * * *******************
   * * ANIMATION
   * * *******************
   */
  start() {
    this.isStarted = true;
  }

  stop(callback) {
    this.isStarted = false;
    // TODO callback when all lines are hidden
  }

  /**
   * * *******************
   * * LINES
   * * *******************
   */
  // linesにlineオブジェクトをpush
  addLine(props) {
    //このpropsはaddの度に違う値が入ってきたものが入る
    // Object.assignでオブジェクトを一つのオブジェクトにマージ https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
    const line = new AnimatedMeshLine(Object.assign({}, this.lineStaticProps, props));
    this.lines.push(line);
    this.add(line);
    this.nbrOfLines++;
    return line;
  }

  removeLine(line) {
    // メモリリーク防止
    line.material.dispose();
    line.geometry.dispose();
    this.remove(line);
    //this.lines配列の該当の線の削除はupdate内でフィルタリングされる(ややこしい)
    this.nbrOfLines--;
  }


  /**
   * * *******************
   * * UPDATE
   * * *******************
   */
  update() {
    // Add lines randomly
    // 確率に基づいて、frequency以下だったら線を追加
    // ここで実行されるaddLineはconstructorでbindされてないので、継承した側のthisになる。(index.jsのCustomLineGenerator)
    if (this.isStarted && Math.random() < this.frequency) this.addLine();
    // Update current Lines
    // 各線のポジションをアップデート this.iを-1になるまで なぜthis.iにするのかは不明(ゴミが残るから??)
    for (this.i = this.nbrOfLines; this.i >= 0; this.i--) {
      this.lines[this.i].update();
    }

    // Filter and remove died lines
    const filteredLines = [];
    for (this.i = this.nbrOfLines; this.i >= 0; this.i--) {
      //線がisDied判定されたらその線をobject3Dから消す
      if (this.lines[this.i].isDied()) {
        this.removeLine(this.lines[this.i]);
      } else {
        // isDiedじゃない線だけ配列に残す。
        filteredLines.push(this.lines[this.i]);
      }
    }
    // this.linesをisDiedじゃない線だけにするs
    this.lines = filteredLines;
  }
}