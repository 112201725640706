import { Color, Vector3 } from 'three';
import Engine from './utils/engine';
import { TimelineLite,Power3 } from 'gsap';
import LineGenerator from './objects/LineGenerator';

import getRandomFloat from './utils/getRandomFloat';
import getRandomItem from './utils/getRandomItem';

import HandleCameraOrbit from './decorators/HandleCameraOrbit';
import FullScreenInBackground from './decorators/FullScreenInBackground';
import app from './app';



export default function () {
  //engineで作成したcanvasをbodyに追加し、フルスクリーン用にリサイズし、リサイズ時はengine.resizeを呼ぶデコレーション。engineに初期横幅、縦幅、バックグラウンドカラー、zを与える役割もある
  // @FullScreenInBackground({ backgroundColor: "#f79ea3"})
  @FullScreenInBackground({ backgroundColor: "#ffffff"})
  //postエフェクトを追加
  // @PostProcessing
  //マウスが動いた時にカメラを動かす。x,yはカメラの振り幅。 lookAtで原点(0,0,0)を指定している。
  @HandleCameraOrbit({ x: 4, y: 4 })
  //engineはカメラ、シーン、canvas自体を持っており、全体的なコンテナになり、シーンを司るもの。engine経由で3dobjを追加したり、ここのカメラをいじったり、ここのcanvasをいじったりする。
  //上記2つのデコレーターの関数にTargetとして渡ったあと、それぞれextendされ、継承される。
  class CustomEngine extends Engine {}
  const engine = new CustomEngine();
  // engine.addBlurEffect({ resolutionScale: 2.4 });


  /**
   * * *******************
   * * TITLE
   * * *******************
   */
  //テキストが１文字ずつmesh化され、まとめられたObject3D。アニメーションもセットされていて、showメソッドで文字が現れる。
  // const text = new AnimatedText3D('Test', { color: '#0f070a', size: app.isMobile ? 0.5 : 0.8 });
  // //文字一文字ずつまとまっているcontainer(AnimatedText3D)を * 0.5(半分に)していることで中央に配置している。
  // text.position.x -= text.basePosition * 0.5;
  // // text.position.y -= 0.5;
  // //AnimatedText3D
  // engine.add(text);


  /**
   * * *******************
   * * LIGNES
   * * *******************
   */

  class CustomLineGenerator extends LineGenerator {
    // start() {
    //   const currentFreq = this.frequency;
    //   this.frequency = 1;
    //   setTimeout(() => {
    //     this.frequency = currentFreq;
    //   }, 1000);
    //   super.start();
    // }

    // 今回の線のアニメーションは、orientationの向きで、nbrOfPoints分の点を設定し、その点を結んだ線を記憶しておき、その線をなぞっていくイメージが基本。
    // このaddLineは一本の線を追加するが、その線の曲がりの点をそれぞれ定義し、それを結ぶ線をなぞっていくアニメーションを各線で行う。
    //getRandomFloat(min,max)はmin以上、max以下の間のランダムな値
    addLine() {
      super.addLine({
        length: getRandomFloat(8, 15),
        //線の長さ
        visibleLength: getRandomFloat(0.02, 0.05),
        //線のポジション(初期位置から曲がった線を引く)
        position: new Vector3(
          (Math.random() - 0.5) * 1.5,
          Math.random() - 1,
          (Math.random() - 0.5),
        ).multiplyScalar(getRandomFloat(5, 20)),
        //揺れのための乱数
        turbulence: new Vector3(
          getRandomFloat(-0.5, 0.5),
          getRandomFloat(0, 0.4),
          getRandomFloat(-0.5, 0.5),
        ),
        //向かっていく方向 これが加算されていくイメージ
        orientation: new Vector3(
          getRandomFloat(-0.8, 0.8),
          -1,
          1,
        ),
        //スピード
        speed: getRandomFloat(0.0028, 0.0056),
        //色をランダムで指定
        color: getRandomItem(COLORS),
      });
    }
  }

  // three.jsのcolorオブジェクトを生成。配列に入れとく
  // https://threejs.org/docs/#api/en/math/Color
  const COLORS = ['#FFD3D4', '#FFFFFF', '#FFDE68', '#FEE2B9', '#FEEDC1'].map((col) => new Color(col));
  // LineGeneratorを経由してAnimatedMeshLineに渡すプロパティ
  const lineWidth = app.isMobile ? 0.3 : 0.15;
  const STATIC_PROPS = {
    width: lineWidth,
    nbrOfPoints: 6, //線の曲がり目(セグメント)の数
  };
  //frequencyは線がupload時に新しく追加される確率。低ければ線は少なく、高ければ多い
  const lineGenerator = new CustomLineGenerator({
    frequency: 0.4
  }, STATIC_PROPS);

  engine.add(lineGenerator);

  /**
   * * *******************
   * * START
   * * *******************
   */
  // Show
  engine.start();
  //0.2秒後にアニメーション tlShowがスタート
  const tlShow = new TimelineLite({ delay: 0.2, onStart: () => {
    lineGenerator.start();
  }});
  tlShow.to('.overlay', 0.6, { autoAlpha: 0 });
  //.fromTo( target:Object, duration:Number, fromVars:Object, toVars:Object, position:* ) 
  //カメラが上向きから下向きに
  tlShow.fromTo(engine.lookAt, 3, { y: -4 }, { y: 0, ease: Power3.easeOut }, '-=0.4');
  //.add( value:*(callbackなど), position:*(オフセット ずらし時間), align:String, stagger:Number ) : *
  //テキストアニメーションが実行される。
  // tlShow.add(text.show, '-=2');
  // blurアニメーション
  tlShow.add(()=>{
    // engine.dom.classList.add("active");
  }, '+=3');
}
