import { createStore } from 'vuex'
import axios from "axios";

const sleep = (time) => {
  return new Promise((resolve, reject) => {
      setTimeout(() => {
          resolve();
      }, time);
  });
}

export default createStore({
  state: {
    initOpen: false,
    isOpen: false,
    categoryDefinition: null,
    departments: null,
    products: null,
    isFirstLoad: true,
    homeAnimationFlg: false,
  },

  mutations: {
    toggleInitOpen(state) {
      state.initOpen = !state.initOpen;
    },
    toggleOpen (state) {
      state.isOpen = !state.isOpen;
    },
    toggleHomeAnimation (state) {
      state.homeAnimationFlg = !state.homeAnimationFlg;
    },
    alreadyLoad (state) {
      state.isFirstLoad = false;
    },
    storeCategoryDefinitions (state, definitions) {
      state.categoryDefinition = definitions
    },
    storeDepartments (state, departments) {
      state.departments = departments
    },
    storeProducts (state, products) {
      state.products = products
    },
  },
  actions: {
    async setCategoryDefinitions({ commit },option) {
      const retryArr = Array.from({length: option.numOfTime}, (v, k) => k);
      let isResponse = null;
      for(let i of retryArr){
        await axios.get("/dataSources/categoryDefinition.json")
          .then(function(res){
            commit("storeCategoryDefinitions", res.data)
            isResponse = res
            return Promise.resolve(res.data);
          }).catch(function(error){
            isResponse = error;
            return Promise.resolve(error);
          });
        if(isResponse && isResponse.data.categorys !== undefined && isResponse.data.categorys.length) {
          return Promise.resolve(isResponse.data);
        }
        await sleep(option.delay);
      }
    },

    async setDepartments({ commit },option) {
      const retryArr = Array.from({length: option.numOfTime}, (v, k) => k);
      let isResponse = null;
      for(let i of retryArr){
        await axios.get("/dataSources/departments.json")
          .then(function(res){
            commit("storeDepartments", res.data)
            isResponse = res
            return Promise.resolve(res.data);
          }).catch(function(error){
            isResponse = error;
            return Promise.resolve(error);
          });
        if(isResponse && isResponse.data.babycar !== undefined) {
          return Promise.resolve(isResponse.data);
        }
        await sleep(option.delay);
      }
    },

    async setProducts({ commit },option) {
      const retryArr = Array.from({length: option.numOfTime}, (v, k) => k);
      let isResponse = null;
      for(let i of retryArr){
        await axios.get("/dataSources/products.json")
            .then(function(res){
              commit("storeProducts", res.data)
              isResponse = res
              return Promise.resolve(res.data);
            }).catch(function(error){
              isResponse = error;
              return Promise.resolve(error);
            });
        if(isResponse && isResponse.data.babycar !== undefined) {
          return Promise.resolve(isResponse.data);
        }
        await sleep(option.delay);
      }
    }
  },
  modules: {
  }
})
