<template>
<header class="menu">
  <div><span></span></div>
  <div><span></span></div>
  <div><span></span></div>
</header>

<h3 class="toTopLogo">
  <router-link :to="{path:'/'}">
    <img class="bg_circle" src="@/assets/images/mv_circle.png">
    <img class="logo_icon" src="@/assets/images/mv_logo.svg" alt="2023 トモニテ 子育て大賞">
  </router-link>
</h3>

<div id="navigation">
  <div class="background"></div>
  <div class="fixWrapper">
    <div class="contentsArea">
      <section class="awards_categorys">
        <h3 class="logoTitle">
          <router-link v-on:click="hiddenNavigationIfNeeded()" :to="{path:'/'}">
            <img class="bg_circle" src="@/assets/images/mv_circle.png">
            <img class="logo_icon" src="@/assets/images/mv_logo.svg" alt="2023 トモニテ 子育て大賞">
          </router-link>
        </h3>
        <h3 class="title">受賞ジャンル一覧</h3>
        <section class="categoryItem">
          <section class="categorys">
            <ul class="items">
              <template v-for="(category) in categoryDefinition.categorys" :key="category.id">
                <template v-if="category.id !== 'department-of-expectations'">
                  <router-link :to="{path: `/${category.id}`}">
                    <li class="categoryItem" v-bind:class="category.id">
                      <p >{{ category.name }}</p>
                      <span class="allowBg"><span class="circle"></span></span>
                    </li>
                  </router-link>
                </template>
                <template v-else>
                  <router-link v-on:click="hiddenNavigationIfNeeded()" :to="{path: `/`, hash: '#'+category.id}" >
                    <li class="categoryItem" v-bind:class="category.id">
                      <p >{{ category.name }}</p>
                      <span class="allowBg"><span class="circle"></span></span>
                    </li>
                  </router-link>
                </template>
              </template>
            </ul>
          </section>
        </section>
        <p class="tominiteLogo"><a href="https://tomonite.com/" target="_blank"><img src="@/assets/images/tomonite_logo.png" alt="ともに手をとる子育てを トモニテ"></a></p>
        <p class="subline">FOLLOW US!!</p>
        <ul class="links">
          <li class="facebook"><a href="https://www.facebook.com/tomonite" target="_blank"><img src="@/assets/images/facebook_p.png" alt="facebook"></a></li>
          <li class="instagram"><a href="https://www.instagram.com/tomonite_official/" target="_blank"><img src="@/assets/images/instagram_p.png" alt="instagram"></a></li>
          <li class="x"><a href="https://twitter.com/tomonite_jp" target="_blank"><img src="@/assets/images/x_p.png" alt="x"></a></li>
          <li class="youtube"><a href="https://www.youtube.com/channel/UC26H3H7aEUnMwF7hztP07Og" target="_blank"><img src="@/assets/images/youtube_p.png" alt="youtube"></a></li>
        </ul>
      </section>
    </div>
  </div>
</div>
</template>

<script>
import util from '@/jsUtil/util'
import { TimelineLite } from 'gsap'
import store from '@/store/index'
export default {
  name: 'Header',

  data () {
    return {
      events: {},
      navProggress: false,
      categoryDefinition: [],
      unsubscribe: null
    }
  },

  mounted () {
    this.toggleLogo();
    const menuEl = document.querySelector(".menu");
    const navEl = document.querySelector("#navigation");
    this.set_nav(menuEl,navEl);
    //headerは基本的にappと同一なので再取得などは行わない。
    this.unsubscribe = store.subscribe((mutation, state) => {
      switch(mutation.type) {
        case "storeCategoryDefinitions":
          if (store.state.categoryDefinition != null) {
            // vuex自体が変更されてしまうのでローカルで保持
            // 逆に全部反映させたい場合はそのまま代入する
            this.categoryDefinition = JSON.parse(JSON.stringify(store.state.categoryDefinition))
          }
          break;
      }
    });

    if (store.state.categoryDefinition != null) {
      // vuex自体が変更されてしまうのでローカルで保持
      // 逆に全部反映させたい場合はそのまま代入する
      this.categoryDefinition = JSON.parse(JSON.stringify(store.state.categoryDefinition));
    }
  },

  methods: {
    toggleLogo() {
      this.events["toggleLogo"] = () => {
        const currentScroll = util.getScrollTop();
        const flgPosition = 900;
        const logoEl = document.querySelector(".toTopLogo");
        const anchorsEl = document.querySelector(".PcAnchors");
        const stickyAnchorNav = document.querySelector("#stickyAnchorNav .inner");
        const anchorListLength = document.querySelectorAll(".PcAnchors ul li").length;
        if(currentScroll >= flgPosition) {
          util.addactive(logoEl);
          if (anchorListLength > 1) {
            util.addactive(anchorsEl);
            util.addactive(stickyAnchorNav, "navShown");
          }
        } else {
          util.removeactive(logoEl);
          util.removeactive(anchorsEl);
          util.removeactive(stickyAnchorNav, "navShown");
        }
      }
      window.addEventListener("scroll", this.events["toggleLogo"]);
    },

    set_nav(menu,nav) {
      let self = this;
      const elbody = document.querySelector("body");
      menu.addEventListener("click",()=>{
        if(menu.classList.contains("active") && !self.navProggress) {
          self.navProggress = true;
          elbody.style.overflow = "visible";
          util.addactive(nav, "deactive");
          util.removeactive(menu);
          util.removeactive(nav);
          setTimeout(()=>{
            nav.style.display = "none";
            self.navProggress = false;
          },800);
        } else if( !self.navProggress ) {
          self.navProggress = true;
          let tl = new TimelineLite({ onComplete: () => {
            util.removeactive(nav, "deactive");
            util.addactive(menu);
            nav.style.display = "block";
            setTimeout(()=>{
              util.addactive(nav);
              self.navProggress = false;
            },200);
            tl = null;
          }});
          tl.set(elbody,{ overflow: "hidden"});
        }
      },false);
    },

    hiddenNavigationIfNeeded() {
      const menuEl = document.querySelector(".menu");
      const navEl = document.querySelector("#navigation");
      const elbody = document.querySelector("body");
      if(menuEl.classList.contains("active") && window.location.pathname === '/') {
        elbody.style.overflow = "visible";
        util.addactive(navEl, "deactive");
        util.removeactive(menuEl);
        util.removeactive(navEl);
        setTimeout(()=>{
          navEl.style.display = "none";
        },800);
      }
    },
  },

  beforeUnmount() {
    this.unsubscribe();
    this.unsubscribe = null;
  }
}
</script>

<style lang="scss">
.topAnchor {
  position: fixed;
  right: -10%;
  bottom: 12%;
  width: displaysize(60);
  z-index: 5000;
  transform: translate(0);
  will-change: transform,right,opacity;
  backface-visibility:hidden;
  @include transition_anim($propertys: (right,opacity,transform), $duration-time: 1.0s);
  &:hover {
    cursor: pointer;
    transform: scale(1.24);
  }
  &.active {
    right: 4%;
  }
}

.toTopLogo {
  position: fixed;
  width: 120px;
  left: -15%;
  top: 10px;
  transform: translate(0,0);
  z-index: 5000;
  will-change: left;
  backface-visibility:hidden;
  @include transition_anim($propertys: (left,opacity), $duration-time: 1.0s);
  &:hover {
    opacity: 0.5;
  }
  &.active {
    left: 85px;
  }
  a {
    width: 100%;
    display: block;
    position: relative;
  }
  &:before {
    @include content_pseudo;
    left: -14.3%;
    top: -8%;
    width: 128%;
    padding-top: 128%;
    z-index: 0;
    background-image: url("~@/assets/images/logo_shadow.png");
    background-size: 100% auto;
    will-change: transfrom, position;
    opacity: 0.8;
    @keyframes rotate_str_shadow_nav {
      0% { 
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    @include animationProp(rotate_str_shadow_nav, 29.0s, 0.0s, linear);
    animation-iteration-count: infinite;
  }
  .logo_icon {
    position: absolute;
    width: percent_resize(166,217);
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 1000;
  }
  .bg_circle {
    transform-origin: center;
    will-change: transfrom;
    z-index: 500;
    @keyframes rotate_str {
      0% { 
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    @include animationProp(rotate_str, 29.0s, 0.0s, linear);
    animation-iteration-count: infinite;
  }
}

.menu {
  display: block;
  width: displaysize(60);
  height: displaysize(60);
  position: fixed;
  top: 3%;
  right: 3%;
  z-index: 9500;
  @include hoverlink;
  cursor: pointer;
  background-color: #FF7276;
  border-radius: 100%;
  backface-visibility: hidden;
  div {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 50%;
    height: 1px;
    overflow: hidden;
    will-change: transform;
    @include transition_anim($duration-time: 1.0s);
    &:first-child {
      transform: translateY(-1 * displaysize(10));
    }
    &:last-child {
      transform: translateY(displaysize(10));
    }
    span {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left:0;
      right: 0;
      width: 100%;
      height: 1px;
      background-color: #FFFFFF;
      will-change: background-color;
      @include transition_anim($duration-time: 1.0s, $propertys: (background-color));
    }
  }


  &.active {
    div {
      &:first-child {
        transform: rotate(35deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:last-child {
        transform: rotate(-35deg);
      }
    }
  }

  &.forcePink {
    div {
      span {
        background-color: $usufulPink;
      }
    }
  }
}

#navigation {
  // テスト用
  position: fixed;
  display: none;
  z-index: 9200;
  width: 100vw;
  height: 100vh;
  top: 0;
  backface-visibility: hidden;
  .fixWrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    height: 100vh;
    opacity: 0;
    overflow: auto;
    will-change: opacity;
    backface-visibility: hidden;
    @include transition_anim($duration-time: 1.6s);
  }
  .background {
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    background-color: $color_white;
  }
  .contentsArea {
    position: absolute;
    @include clearfix;
    width: percent_resize(1088,1920);
    min-width: 900px;
    padding: 7vh 0 7vh 0;
    left: 50%;
    transform: translate(-50%,0);
    overflow: visible;
    @include resizebyheight_morethan(970px){
      padding-top: 20vh;
    }
    @include resizebyheight_morethan(1100px){
      padding-top: 25vh;
    }
  }
  &.active {
    .fixWrapper {
      // @include animationProp(openNavigationCon, 0.65s, 0, $cubic-bezir);
      opacity: 1;
      // animation-delay: 0.7s;
    }
    .background {
      // @include animationProp(openNavigation, 0.65s, 0, $cubic-bezir);
      opacity: 1;
      // @include resizebywidth_lessthan($base_resize_size){
      //   @include animationProp(openNavigation_sp, 0.65s, 0, $cubic-bezir);
      // }
    }
  }
  .awards_categorys {
    position: relative;
    text-align: center;
    .logoTitle {
      position: relative;
      width: percent_resize(264,1088);
      margin: 0 auto percent_resize(80,1088) auto;
      img {
        width: 100%;
      }
      &:before {
        @include content_pseudo;
        left: -14.3%;
        top: -11.5%;
        width: 128%;
        padding-top: 128%;
        z-index: 0;
        background-image: url("~@/assets/images/logo_shadow.png");
        background-size: 100% auto;
        will-change: transfrom, position;
        opacity: 0.8;
        @keyframes rotate_str_shadow {
          0% { 
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        @include animationProp(rotate_str_shadow, 32.0s, 0.0s, linear);
        animation-iteration-count: infinite;
      }
      .logo_icon {
        position: absolute;
        width: 78%;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        z-index: 1000;
      }
      .bg_circle {
        will-change: transfrom;
        @keyframes rotate_str {
          0% { 
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        @include animationProp(rotate_str, 32.0s, 0.0s, linear);
        animation-iteration-count: infinite;
        z-index: 500;
      }
    }
    .categorys {
      width: 100%;
      margin-bottom: percent_resize(100,1088);
      .items {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        a {
          display: block;
          width: 23.9%;
          margin-right: 1.4%;
          &:nth-of-type(4n) {
            margin-right: 0;
          }
          &:hover {
            .categoryItem {
              .allowBg {
                .circle {
                  background-color: $fontPink;
                  width: 130%;
                  padding-top: 130%;
                  height: auto;
                  top: -15%;
                  left: -15%;
                }
                &:after {
                  opacity: 0.0;
                }
                &:before {
                  opacity: 1.0;
                }
              }
            }
          }
          .categoryItem {
            display: flex;
            justify-content: flex-start;
            border-bottom: 1px solid #CCCCCC;
            box-sizing: border-box;
            padding-bottom: displaysize(15);
            align-items: center;
            margin-bottom: displaysize(21);
            p {
              color: #FF8E91;
              font-size: displaysize(16);
            }
            .allowBg {
              display: block;
              position: relative;
              width: displaysize(20);
              padding-top: displaysize(20);
              box-sizing: border-box;
              margin-left: auto;
              .circle {
                display: block;
                position: absolute;
                width: 100%;
                padding-top: 100%;
                border-radius: 50%;
                border: 1px solid #FF8E91;
                left: 0;
                top: 0;
                z-index: 0;
                will-change: background-color,padding-top,width;
                background-color: transparent;
                @include transition_anim($duration-time: 0.8s, $propertys: (background-color,width,padding-top,left,top));
                backface-visibility: hidden;
              }
              &:after {
                @include content_pseudo;
                width: 50%;
                height: 50%;
                background-size: 100% auto;
                left: 55%;
                top: 53%;
                transform: translate(-50%,-50%);
                z-index: 100;
                background-image: url("~@/assets/images/icon_arrow_pink.png");
                background-repeat: no-repeat;
                will-change: opacity;
                @include transition_anim($duration-time: 1.0s, $propertys: (opacity));
              }
              &:before {
                @include content_pseudo;
                width: 50%;
                height: 50%;
                background-size: 100% auto;
                left: 55%;
                top: 53%;
                transform: translate(-50%,-50%);
                background-image: url("~@/assets/images/icon_arrow_white.png");
                background-repeat: no-repeat;
                z-index: 200;
                opacity: 0;
                will-change: opacity;
                @include transition_anim($duration-time: 1.0s, $propertys: (opacity));
              }
            }
          }
        }
      }
    }
    .title {
      font-family: 'M PLUS Rounded 1c', sans-serif;
      font-weight: 400;
      color: $fontPink;
      display: inline-block;
      margin: 0 auto percent_resize(52,1088) auto;
      font-size: displaysize(30);
    }
    .tominiteLogo {
      width: percent_resize(261,1088);
      margin: 0 auto percent_resize(47,1088) auto;
      a {
        opacity: 1;
        @include transition_anim();
        &:hover {
          opacity: 0.5;
        }
      }
    }
    .subline {
      font-family: 'M PLUS Rounded 1c', sans-serif;
      font-weight: 400;
      color: $fontPink;
      display: inline-block;
      margin: 0 auto percent_resize(19,1088) auto;
      font-size: displaysize(12);
    }
    .links {
      width: percent_resize(236,1088);
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      a {
        opacity: 1;
        @include transition_anim();
        &:hover {
          opacity: 0.5;
        }
      }
      .facebook {
        width: percent_resize(35,236);
      }
      .instagram {
        width: percent_resize(35,236);
      }
      .x {
        width: percent_resize(31,236);
      }
      .youtube {
        width: percent_resize(41,236);
      }
    }
  }
}

@include resizebywidth_lessthan($base_resize_size){
  .toTopLogo {
    // width: 20%;
    // left: -25%;
    // top: 8%;
    // &:hover {
    //   opacity: 0.5;
    // }
    // &.active {
    //   left: 3%;
    // }
    display: none;
  }
  .topAnchor {
    right: -20%;
    bottom: 5%;
    width: 11%;
    z-index: 5000;
    &.active {
      right: 5%;
    }
  }
  .menu {
    right: 2%;
    top: 1%;
  }
  #navigation {
    .fixWrapper {
      .contentsArea {
        width: 100%;
        min-width: auto;
        max-width: initial;
        transform: none;
        left: auto;
        overflow: hidden;
        padding: percent_resize(50,430) 0 22% 0!important;
        .awards_categorys {
          width: 100%;
          .logoTitle {
            width: percent_resize(180,430);
            margin: 0 auto percent_resize(28,430) auto;
          }
          .title {
            margin: 0 auto percent_resize(28,430) auto;
            font-size: 4.2vw;
          }
          .categorys {
            margin-bottom: percent_resize(49,430);
            .items {
              width: percent_resize(390,430);
              margin: 0 auto;
              a:nth-last-child(2) {
                li {
                  //margin-bottom: 0;
                }
              }
              a {
                width: 48%;
                margin-right: 4%;
                &:nth-of-type(2n) {
                  margin-right: 0;
                }
                &:last-of-type {
                  li {
                    margin-bottom: 0;
                  }
                }
                .categoryItem {
                  padding-bottom: percent_resize(19,241);
                  margin-bottom: percent_resize(41,241);
                }
                p {
                  font-size: 3.6vw;
                }
              }
            }
          }
          .tominiteLogo {
            width: percent_resize(204,430);
          }
          .subline {
            font-size: 2.7vw;
            margin: 0 auto percent_resize(33,430) auto;
          }
          .links {
            width: percent_resize(231,430);
          }
        }
      }
    }
  }
}
</style>
