export default ({ backgroundColor = undefined, z = 10 } = {}) =>
 (Target) => class FullScreenInBackground extends Target {
  constructor() {
    //engineに渡すバックグラウンドカラーとカメラのz値、ウィンドウのサイズを渡す。
    let props = {w: window.innerWidth, h: window.innerHeight, backgroundColor: backgroundColor, z:z };
    super(props);

    // Put automaticaly the canvas in background
    // canvasを設置し、リサイズに対応
    this.dom.style.position = 'fixed';
    this.dom.style.top = '0';
    this.dom.style.left = '0';
    this.dom.style.zIndex = '-1';
    document.body.appendChild(this.dom);

    this.resize = this.resize.bind(this);

    window.addEventListener('resize', this.resize);
    window.addEventListener('orientationchange', this.resize);
    this.resize();
  }

  resize() {
    super.resize(window.innerWidth, window.innerHeight);
  }
};
