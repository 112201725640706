<template>
<div id="wrapper">
  <Header/>
  <div class="bgLayer"></div>
  <div class="PageAnimation" v-bind:class="{ initPosition: initOpen, after: (isOpen && !initOpen), before: (!isOpen && !initOpen)}">
    <div class="innerBgLayer"></div>
    <div class="opening">
      <div class="bgCircle">
        <div class="scale">
          <img src="@/assets/images/mv_circle.png" alt="">
        </div>
      </div>
      <p class="loading"><img src="@/assets/images/loading.png" alt="loading..."></p>
    </div>
  </div>
  <router-view/>
  <Footer/>
</div>
</template>


<script>
import store from '@/store/index'
import lineAnimation from '@/lineAnimation/generateAnimation'
import Footer from '@/components/Footer'
import Header from '@/components/Header'
export default {


  components: {
    Footer,
    Header,
  },

  data () {
    return {
      isOpen: false,
      initOpen: false,
      isFirstLoad: true,
      unsubscribe: null,
    }
  },

  created () {
    this.getCategorysDefinition(500,60);
    this.getDepartments(500,60);
    this.getProducts(500,60);
  },

  mounted () {
    this.unsubscribe = store.subscribe((mutation, state) => {
      switch(mutation.type) {
        case "toggleOpen":
          this.isOpen = state.isOpen
          break;
        case "toggleInitOpen":
          this.initOpen = state.initOpen
          break;
        case "alreadyLoad":
          this.isFirstLoad = state.isFirstLoad
          break;
      }
    });
    lineAnimation();
  },

  methods: {
    async getCategorysDefinition(delay, numOfTime) {
      store.dispatch("setCategoryDefinitions",{ delay: delay, numOfTime: numOfTime });
    },

    async getDepartments(delay, numOfTime) {
      store.dispatch("setDepartments", { delay: delay, numOfTime: numOfTime });
    },

    async getProducts(delay, numOfTime) {
      store.dispatch("setProducts", { delay: delay, numOfTime: numOfTime });
    },
  },

  beforeUnmount() {
    this.unsubscribe();
    this.unsubscribe = null;
  }
}
</script>

<style lang="scss">
@keyframes MovetoRightAndDisappearBaseArrow {
  0% {
    left: 50%;
    opacity: 1;
  }
  100% {
    left: 200%;
    opacity: 0;
  }
}
@keyframes MovetoRightAndDisappearNextArrow {
  0% {
    left: -200%;
    opacity: 0;
  }
  100% {
    left: 50%;
    opacity: 1;
  }
}
@keyframes bounceArrow {
  0%, 20%, 50%, 80%, 100% {transform: translate(-50%,-50%); }
  40% {transform: translate(-20%,-50%); }
  60% {transform: translate(-10%,-50%); }
}


.number {
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
}

.PageAnimation {
  position: fixed;
  display: block;
  top: 0;
  left: 0%;
  width: 100%;
  height: 110%;
  z-index: 9999;
  backface-visibility:hidden;
  overflow: hidden;
  .innerBgLayer {
    position: absolute;
    width: 100%;
    height: 300%;
    backface-visibility:hidden;
    overflow: hidden;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, white 15%, white 40% rgba(255, 255, 255, 0) 51%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0, white 15%, white 40% rgba(255, 255, 255, 0) 51%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0)), color-stop(20%, white), color-stop(40%, white), color-stop(51%, rgba(255, 255, 255, 0)));
    background-repeat: no-repeat;
    background-size: 100% 200%;
    transform-origin: center;
    will-change: top,left;
    @include transition_anim($duration-time: 3.0s, $propertys: (top,left));
    top: 100%;
    left: 0%;
  }
  &.initPosition {
    .innerBgLayer {
      top: 100%;
      left: 0%;
    }
  }
  &.before {
    .innerBgLayer {
      top: -120%;
      left: 0%;
    }
  }
  &.after {
    .innerBgLayer {
      top: -300%;
      left: 0%;
      @include transition_anim($duration-time: 1.0s, $propertys: (top,left));
    }
  }
}

.PageAnimation .opening {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 540px;
  pointer-events: none;
  user-drag: none;
  -webkit-user-drag: none;
  -moz-user-drag: none;
  -ms-user-drag: none;
  .loading {
    position: absolute;
    min-width: 117px;
    left: 50%;
    bottom: 60%;
    width: percent_resize(175,1920);
    transform: translate(-50%,50%);
    will-change: opacity;
    z-index: 100;
    opacity: 1;
    @keyframes transparentation {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @include animationProp(transparentation, 4.0s, 0.0s, linear);
    animation-iteration-count: infinite;
  }
  .bgCircle {
    display: block;
    position: absolute;
    left: 50%;
    top: 40%;
    width: percent_resize(452,1920);
    will-change: transform;
    transform: translate(-50%,-50%);
    transform-origin: center;
    z-index: 1;
    @keyframes rotateAnimation {
      from { transform: translate(-50%,-50%) rotate(0deg); }
      to { transform: translate(-50%,-50%) rotate(360deg); }
    }
    animation: rotateAnimation 10s infinite linear;
    .scale {
      width: 100%;
      will-change: transform;
      transform-origin: center;
      transform: scale(10);
      &.active {
        @keyframes scaleAnimation {
          from { transform: scale(10); }
          to { transform: scale(1); }
        }
        animation: scaleAnimation 3s forwards linear;
      }
    }
  }
}

@include resizebywidth_lessthan($base_resize_size){
  .PageAnimation .opening {
    min-height: initial;
    .loading {
      min-width: auto;
      width: percent_resize(98,375);
    }
    .bgCircle {
      width: 50%;
    }
  }
}

#wrapper {
  width: 100%;
  min-width: 1080px;
  overflow: hidden;
  @include resizebywidth_lessthan($base_resize_size){
    min-width: auto;
  }
}

.text {
  @include font_style_pc;
}

.bgLayer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  z-index: -100;
  background-color: #FFFFFF;
  will-change: background-color;
  backface-visibility: hidden;
}

.awards_categorys {
  box-sizing: border-box;
  .categoryItem {
    @include clearfix;
    position: relative;
    .categorys {
      position: relative;
      width: displaysize(630);
      .pageLink {
        color: #BEBEBE;
        font-size: displaysize(23);
        margin-bottom: percent_resize(43,630);
        a {
          color: rgba($color: #F37477, $alpha: 1.0);
          display: inline-block;
          position: relative;
          &:visited { color: rgba($color: #F37477, $alpha: 1.0); }
          &:link { color: rgba($color: #F37477, $alpha: 1.0); }
          opacity: 1.0;
          @include transition_anim();
          &:hover {
            opacity: 0.6;
          }
        }
      }
      .groups {
        a {
          display: block;
          width: 100%;
          height: 100%;
        }
        a:nth-of-type(n + 2) {
          li {
            margin-top: -1px;
          }
        }
        li {
          position: relative;
          padding: percent_resize(25,630) 0 percent_resize(25,630) 0;
          font-size: displaysize(26);
          letter-spacing: 0.03em;
          color: #404040;
          @include transition_anim($duration-time: 1.0s, $propertys: (border-top,border-bottom));
          border-top: 1px solid #CCCCCC;
          border-bottom: 1px solid #CCCCCC;
          z-index: 100;
          &:hover {
            border-top: 1px solid #000000;
            border-bottom: 1px solid #000000;
            z-index: 500;
          }
        }
      }
    }
  }
}

canvas {
  transition-property: filter,opacity;
  transition-timing-function: ease-out;
  will-change: filter,opacity;
  backface-visibility:hidden;
  overflow: hidden;
  opacity: 0.6;
  z-index: 1;
  filter: blur(8px);
  @include resizebywidth_lessthan($base_resize_size){
    filter: blur(4px);
  }
}
</style>