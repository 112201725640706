
class Util {

    constructor() {
        const document = window.document;
        const body = document.body;

        this.scrollingElement =  'scrollingElement' in document
        ? document.scrollingElement
        : window.navigator.userAgent.indexOf('WebKit') != -1
        ? body
        : document.documentElement || body.parentNode;
    }

    addactive(el, add_class_name = "active") {
        if(el.classList.contains(add_class_name)) { return }
        el.classList.add(add_class_name);
    }
    
    removeactive(el, remove_class_name = "active") {
        if(!el.classList.contains(remove_class_name)) { return }
        el.classList.remove(remove_class_name);
    }

    getScrollTop() {
        let pageYOffset = window.pageYOffset;
        if (pageYOffset !== undefined) { return pageYOffset; }
        return this.scrollingElement.scrollTop;
    }

    getoffsetTop(el){
        let rect = el.getBoundingClientRect();
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return rect.top + scrollTop;
    }

    triggerEvent(element, event) {
        if (document.createEvent) {
            // IE以外
            const evt = document.createEvent("HTMLEvents");
            evt.initEvent(event, true, true );
            return element.dispatchEvent(evt);
        } else {
            // IE
            const evt = document.createEventObject();
            return element.fireEvent("on"+event, evt)
        }
    }

    eq(selector, index) {
        const nodeList = document.querySelectorAll(selector);
        const length = nodeList.length;
        if (0 < index && index < length) {
        return nodeList[index];
        }
        if (0 <= length + index) {
        return nodeList[length + index];
        }
        return null;
    }
}
  
export default new Util();