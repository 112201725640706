<template>
<section class="genreFotter">
  <div class="animationLayer first">
    <div class="slideContainer"><img src="@/assets/images/genre_1.png" alt=""></div>
  </div>
  <div class="animationLayer secound">
    <div class="slideContainer"><img src="@/assets/images/genre_2.png" alt=""></div>
  </div>
  <div class="container">
    <div class="inner">
      <h3 class="title">受賞商品をジャンルごとに見る</h3>
      <ul class="items">
        <template v-for="(category) in categoryDefinition.categorys" :key="category.id">
          <template v-if="category.id !== 'department-of-expectations'">
            <router-link :to="{path: `/${category.id}`}">
              <li class="categoryItem" v-bind:class="category.id">
                <p >{{ category.name }}</p>
                <span class="allowBg"><span class="circle"></span></span>
              </li>
            </router-link>
          </template>
          <template v-else>
            <router-link :to="{path: `/`, hash: '#'+category.id}" >
              <li class="categoryItem" v-bind:class="category.id">
                <p >{{ category.name }}</p>
                <span class="allowBg"><span class="circle"></span></span>
              </li>
            </router-link>
          </template>
        </template>
      </ul>
    </div>
  </div>
</section>

<section class="special">
  <div class="infoContainer">
    <h3 class="headline"><img src="@/assets/images/tomonite_logo.png" alt="ともに手をとる子育てを トモニテ"></h3>
    <div>
      <p class="subtitle">毎日の子育てをアプリ1つで！</p>
      <p class="subline">離乳食も育児記録もこれさえあれば困らない。</p>
    </div>
    <ul class="badgeArea">
      <li class="apple"><a href="https://app.adjust.com/13lt6v7d" target="_blank"><img src="@/assets/images/apple_badge.png" alt="App Store"></a></li>
      <li class="google"><a href="https://play.google.com/store/apps/details?id=tv.every.mamadays" target="_blank"><img src="@/assets/images/google_badge.png" alt="Google Play"></a></li>
    </ul>
  </div>
</section>

<footer>
  <section class="snsLinks">
    <div class="linkContainer">
      <h3>FOLLOW US!!</h3>
      <ul class="links">
        <li class="facebook"><a href="https://www.facebook.com/tomonite" target="_blank"><img src="@/assets/images/facebook.png" alt="facebook"></a></li>
        <li class="instagram"><a href="https://www.instagram.com/tomonite_official/" target="_blank"><img src="@/assets/images/instagram.png" alt="instagram"></a></li>
        <li class="x"><a href="https://twitter.com/tomonite_jp" target="_blank"><img src="@/assets/images/x.png" alt="x"></a></li>
        <li class="youtube"><a href="https://www.youtube.com/channel/UC26H3H7aEUnMwF7hztP07Og" target="_blank"><img src="@/assets/images/youtube.png" alt="youtube"></a></li>
      </ul>
    </div>
    <figure class="phone"><img src="@/assets/images/footer_phone.png" alt=""></figure>
  </section>
  <section class="linkArea">
    <ul class="links">
      <li><a href="https://tomonite.com/" target="_blank">トモニテ公式サイト</a></li>
      <li><a href="https://tomonite.com//contact" target="_blank">お問い合わせ</a></li>
      <li><a href="https://corp.every.tv/" target="_blank">運営会社</a></li>
      <li><a href="https://tomonite.com//terms" target="_blank">利用規約</a></li>
      <li><a href="https://corp.every.tv/privacy" target="_blank">プライバシーポリシー</a></li>
    </ul>
    <p class="copywrite en">© EVERY,INC. 2019-2023</p>
  </section>
</footer>
</template>

<script>
import store from '@/store/index'
import { debounce } from 'throttle-debounce';
export default {
  name: 'Footer',

  data () {
    return {
      events: {},
      categoryDefinition: [],
      cacheWidth: 0,
      isEdge: false,
      isAllowFollowAnimation: false
    }
  },

  mounted(){
    this.unsubscribe = store.subscribe((mutation, state) => {
      switch(mutation.type) {
        case "storeCategoryDefinitions":
          if (store.state.categoryDefinition != null) {
            // vuex自体が変更されてしまうのでローカルで保持
            // 逆に全部反映させたい場合はそのまま代入する
            this.categoryDefinition = JSON.parse(JSON.stringify(store.state.categoryDefinition))
          }
          break;
      }
    });

    if (store.state.categoryDefinition != null) {
      // vuex自体が変更されてしまうのでローカルで保持
      // 逆に全部反映させたい場合はそのまま代入する
      this.categoryDefinition = JSON.parse(JSON.stringify(store.state.categoryDefinition));
    }

    this.setWindowSizeCheck();
  },

  methods: {

    reloadHorizontalAnimation(){
      const targetsEl = document.querySelectorAll(".animationLayer");
      return debounce(1000, () => {
        const docWidth = document.body.clientWidth;
        if ( Math.abs(this.cacheWidth - docWidth) > 30 ) {
          this.cacheWidth = docWidth;
          targetsEl.forEach((el,i) => {
            el.style.display = "none";
          });
          setTimeout(()=>{
            targetsEl.forEach((el,i) => {
              el.style.display = "flex";
            });
          },300);
        }
      });
    },

    setWindowSizeCheck(){
      this.events["reloadHorizontalAnimation"] = this.reloadHorizontalAnimation();
      window.addEventListener("resize",this.events["reloadHorizontalAnimation"]);
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

@keyframes loop_for_one {
    0% {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-50%);
    }
}

@keyframes inverseloop_for_one {
    0% {
      transform: translateX(-50%);
    }
    to {
      transform: translateX(0%);
    }
}

.genreFotter {
  position: relative;
  background-color: transparent;
  overflow: hidden;
  .animationLayer {
    position: absolute;
    z-index: 100;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    top: 8%;
    width: percent_resize(7000,1920);
    @include resizebywidth_lessthan($base_resize_size){
      width: 900%;
      top: 5%;
    }
    .slideContainer {
      width: percent_resize(7000,1920);
      display: flex;
      flex-flow: row nowrap;
      margin: 0;
      padding: 0;
      height: auto;
      animation-play-state: running!important;
      img {
        align-self: flex-start;
      }
    }
    > div:nth-of-type(1){
      transform: translateX(0%);
      animation: loop_for_one 32s 0s linear infinite;
    }
    &.secound {
      top: 79%;
      width: percent_resize(7000,1920);
      > div:nth-of-type(1){
        width: percent_resize(7000,1920);
        transform: translateX(-50%);
        animation: inverseloop_for_one 32s 0s linear infinite;
      }
      @include resizebywidth_lessthan($base_resize_size){
        top: 83%;
        width: 900%;
      }
    }
    > div {
        will-change: transform;
    }
  }
  .container {
    position: relative;
    z-index: 300;
    padding-top: percent_resize(422,1920);
    padding-bottom: percent_resize(422,1920);
    @include resizebywidth_lessthan($base_resize_size){
      padding: percent_resize(180,430) 0 percent_resize(203,430) 0;
    }
    .inner {
      width: percent_resize(1088,1920);
      margin: 0 auto;
      text-align: center;
      color: #FF8E91;
      @include resizebywidth_lessthan($base_resize_size){
        width: percent_resize(390,430);
      }
      .title {
        font-size: displaysize(30);
        display: inline-block;
        font-family: 'M PLUS Rounded 1c', sans-serif;
        font-weight: 400;
        white-space: nowrap;
        margin: 0 auto displaysize(101) auto;
        @include resizebywidth_lessthan($base_resize_size){
          font-size: 5.2vw;
          margin: 0 auto percent_resize(38,390) auto;
        }
      }
      .items {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        @include resizebywidth_lessthan($base_resize_size){
          //justify-content: center;
        }
        a {
          display: block;
          width: 23.9%;
          margin-right: 1.4%;
          @include resizebywidth_lessthan($base_resize_size){
            width: 46%;
            margin-right: 8%;
            &:nth-of-type(2n) {
              margin-right: 0;
            }
          }
          &:hover {
            .categoryItem {
              .allowBg {
                .circle {
                  background-color: $fontPink;
                  width: 130%;
                  padding-top: 130%;
                  height: auto;
                  top: -15%;
                  left: -15%;
                }
                &:after {
                  opacity: 0.0;
                }
                &:before {
                  opacity: 1.0;
                }
              }
            }
          }
          &:nth-of-type(4n) {
            margin-right: 0;
          }
          .categoryItem {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #CCCCCC;
            box-sizing: border-box;
            padding-bottom: displaysize(15);
            align-items: center;
            margin-bottom: displaysize(21);
            p {
              color: #FF8E91;
              font-size: displaysize(16);
              @include resizebywidth_lessthan($base_resize_size){
                font-size: 3.6vw;
              }
            }
            .allowBg {
              display: block;
              position: relative;
              width: displaysize(20);
              padding-top: displaysize(20);
              box-sizing: border-box;
              @include resizebywidth_lessthan($base_resize_size){
                width: percent_resize(20,163);
                padding-top: percent_resize(20,163);
              }
              .circle {
                display: block;
                position: absolute;
                width: 100%;
                padding-top: 100%;
                border-radius: 50%;
                border: 1px solid #FF8E91;
                left: 0;
                top: 0;
                z-index: 0;
                will-change: background-color,padding-top,width;
                background-color: transparent;
                @include transition_anim($duration-time: 0.8s, $propertys: (background-color,width,padding-top,left,top));
                backface-visibility: hidden;
              }
              &:after {
                @include content_pseudo;
                width: 50%;
                height: 50%;
                background-size: 100% auto;
                left: 55%;
                top: 53%;
                transform: translate(-50%,-50%);
                z-index: 100;
                background-image: url("~@/assets/images/icon_arrow_pink.png");
                background-repeat: no-repeat;
                will-change: opacity;
                @include transition_anim($duration-time: 1.0s, $propertys: (opacity));
                @include resizebywidth_lessthan($base_resize_size){
                  left: 55%;
                  top: 57%;
                }
              }
              &:before {
                @include content_pseudo;
                width: 50%;
                height: 50%;
                background-size: 100% auto;
                left: 55%;
                top: 53%;
                transform: translate(-50%,-50%);
                background-image: url("~@/assets/images/icon_arrow_white.png");
                background-repeat: no-repeat;
                z-index: 200;
                opacity: 0;
                will-change: opacity;
                @include transition_anim($duration-time: 1.0s, $propertys: (opacity));
              }
            }
          }
        }
      }
    }
  }
}

.special {
  background: linear-gradient(180deg, #FFF4F4, #E9F8F6);
  padding: percent_resize(172,1920) 0 percent_resize(172,1920) 0;
  @include resizebywidth_lessthan($base_resize_size){
    padding: percent_resize(54,430) 0 percent_resize(567,430) 0;
  }
  .infoContainer {
    width: percent_resize(554,1920);
    margin: 0 percent_resize(406,1920) 0 auto;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-weight: 400;
    text-align: center;
    position: relative;
    z-index: 100;
    @include resizebywidth_lessthan($base_resize_size){
      width: percent_resize(367,430);
      margin: 0 auto;
    }
    .headline {
      width: percent_resize(489,554);
      margin: 0 auto percent_resize(49,554);
      @include resizebywidth_lessthan($base_resize_size){
        width: percent_resize(325,367);
        margin: 0 auto percent_resize(32,367);
      }
    }
    .subtitle {
      font-size: percent_resize_vw(30,1920);
      display: inline-block;
      white-space: nowrap;
      margin-bottom: percent_resize(15,554);
      @include resizebywidth_lessthan($base_resize_size){
        font-size: 4.2vw;
        display: block;
        margin: 0 0 percent_resize(13,367) 0;
      }
    }
    .subline {
      font-size: percent_resize_vw(20,1920);
      display: inline-block;
      white-space: nowrap;
      margin-bottom: percent_resize(58,554);
      @include resizebywidth_lessthan($base_resize_size){
        font-size: 3.2vw;
        display: block;
        margin: 0 0 percent_resize(38,367) 0;
      }
    }
    .badgeArea {
      display: flex;
      justify-content: space-between;
      align-items: center;
      li {
        &:hover {
          opacity: 0.6;
        }
        @include transition_anim($duration-time: 0.8s, $propertys: (opacity));
      }
      .apple {
        width: percent_resize(239,554);
        @include resizebywidth_lessthan($base_resize_size){
          width: percent_resize(165,367);
        }
      }
      .google {
        width: percent_resize(262,554);
        @include resizebywidth_lessthan($base_resize_size){
          width: percent_resize(185,367);
        }
      }
    }
  }
}

footer {
  .snsLinks {
    background-color: #FFB8BA;
    padding: displaysize(85) 0;
    position: relative;
    @include resizebywidth_lessthan($base_resize_size){
      padding: percent_resize(120,430) 0 percent_resize(60,430) 0;
    }
    .phone {
      position: absolute;
      width: percent_resize(726,1920);
      top: 0;
      left: 14%;
      margin-top: -37.5%;
      z-index: 0;
      img {
        width: 100%;
      }
      @include resizebywidth_lessthan($base_resize_size){
        width: percent_resize(600,430);
        margin-top: -139%;
        left: -21%;
      }
    }
    .linkContainer {
      width: percent_resize(740,1920);
      margin: 0 percent_resize(220,1920) 0 auto;
      display: flex;
      align-items: center;
      @include resizebywidth_lessthan($base_resize_size){
        width: percent_resize(211,430);
        margin: 0 auto;
        display: block;
        text-align: center;
      }
      h3 {
        align-items: flex-start;
        font-size: displaysize(30);
        color: #FFFFFF;
        white-space: nowrap;
        margin-right: percent_resize(76,1920);
        font-family: 'M PLUS Rounded 1c', sans-serif;
        font-weight: 400;
        @include resizebywidth_lessthan($base_resize_size){
          font-size: 4.2vw;
          margin-bottom: percent_resize(30,211);
        }
      }
      .links {
        width: percent_resize(328,740);
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include resizebywidth_lessthan($base_resize_size){
          width: 100%;
        }
        li {
          @include transition_anim($duration-time: 0.8s, $propertys: (opacity));
          &:hover {
            opacity: 0.6;
          }
        }
        .facebook {
          width: percent_resize(50,328);
          @include resizebywidth_lessthan($base_resize_size){
            width: percent_resize(32,211);
          }
        }
        .instagram {
          width: percent_resize(50,328);
          @include resizebywidth_lessthan($base_resize_size){
            width: percent_resize(32,211);
          }
        }
        .x {
          width: percent_resize(44,328);
          @include resizebywidth_lessthan($base_resize_size){
            width: percent_resize(28,211);
          }
        }
        .youtube {
          width: percent_resize(59,328);
          @include resizebywidth_lessthan($base_resize_size){
            width: percent_resize(38,211);
          }
        }
      }
    }
    color: #FFFFFF;
  }
  .linkArea {
    position: relative;
    padding: displaysize(144) 0;
    text-align: center;
    background-color: #FFFFFF;
    @include resizebywidth_lessthan($base_resize_size){
      padding: percent_resize(66,430) 0;
    }
    .copywrite {
      display: inline-block;
      float: left;
      margin-left: displaysize(144);
      font-size: displaysize(12);
      @include resizebywidth_lessthan($base_resize_size){
        float: none;
        font-size: 2.7vw;
        margin: 0;
      }
    }
    .links {
      float: right;
      display: flex;
      margin-right: displaysize(139);
      font-size: displaysize(12);
      @include resizebywidth_lessthan($base_resize_size){
        float: none;
        width: percent_resize(328,420);
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 auto percent_resize(88,420) auto;
        font-size: 2.7vw;
        line-height: 2.5em;
      }
      li {
        padding-right: displaysize(40);
        position: relative;
        letter-spacing: 0.05em;
        a {
          @include hoverlink
        }
        &:after {
          @include content_pseudo;
          top: 50%;
          right: displaysize(20);
          width: 1px;
          height: 60%;
          transform: translate(0, -50%);
          background-color: $dakenGray;
        }
        &:last-child {
          padding-right: 0;
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
</style>
