import {
  Mesh, Vector3, SplineCurve, Geometry, Color,
} from 'three';
import { MeshLine, MeshLineMaterial } from 'three.meshline';

import getRandomFloat from '../utils/getRandomFloat';

//constructorの引数の{hoge: 0} = {}のような形はデフォルト引数。分割代入することで実現している
export default class AnimatedMeshLine extends Mesh {
  constructor({
    width = 0.1,
    speed = 0.01,
    visibleLength = 0.5,
    color = new Color('#000000'),
    opacity = 1,
    position = new Vector3(0, 0, 0),

    // Array of points already done
    points = false,
    // Params to create the array of points
    length = 2,
    nbrOfPoints = 3,
    orientation = new Vector3(1, 0, 0),
    turbulence = new Vector3(0, 0, 0),
    transformLineMethod = false,
  } = {}) {
    // * ******************************
    // * Create the main line
    let linePoints = [];
    if (!points) {
      // 初期位置
      const currentPoint = new Vector3(0,10,0);
      // 今回の線のアニメーションは、orientationの向きで、nbrOfPoints分の点を設定し、その点を結んだ線を記憶しておき、その線をなぞっていくイメージが基本。
      // orientationはどこへ向かっていくか。
      //https://www.peko-step.com/math/vectorlength.html  normalizeはベクトルの正規化 √x² + y² + z²
      // multiplyScalarはベクトルとスカラーの積。ここでいうと  正規化されたベクトル * (length(8~15) / nbrOfPoints(6)) となる
      // length / nbrOfPointsはよくわからない。。* 1.5(数は適当)分積分された分に向かわせている感じ
      const segment = orientation.normalize().multiplyScalar(length / nbrOfPoints);
      // 原点を格納
      linePoints.push(currentPoint.clone());
      // nbrOfPointsは曲がる回数みたいなもの
      for (let i = 0; i < nbrOfPoints - 1; i++) {
        // orientationの向きから生成されたの値を初期位置に追加
        // nbrOfPoints回segmentを足し続けていく。なのでsegmentで定義された方向に向かい続けるようになる。
        currentPoint.add(segment);
        // 上記のポイントからランダムなノイズを追加し、曲がり点としてpush
        linePoints.push(currentPoint.clone().set(
          currentPoint.x + getRandomFloat(-turbulence.x, turbulence.x),
          currentPoint.y + getRandomFloat(-turbulence.y, turbulence.y),
          currentPoint.z + getRandomFloat(-turbulence.z, turbulence.z),
        ));
      }
      // 最後の点はノイズなしに
      linePoints.push(currentPoint.add(segment).clone());
      // * ******************************
      // * Smooth the line
      // TODO 3D spline curve https://math.stackexchange.com/questions/577641/how-to-calculate-interpolating-splines-in-3d-space
      // TODO https://github.com/mrdoob/three.js/blob/master/examples/webgl_geometry_nurbs.html
      // ポイントとの間を滑らかにしてくれるもの
      const curve = new SplineCurve(linePoints);
      // 滑らかにした線を50分割し、その点を元にgeometryを作成
      linePoints = new Geometry().setFromPoints(curve.getPoints(50));
    } else {
      linePoints = points;
    }



    // * ******************************
    // * Create the MeshLineGeometry
    const line = new MeshLine();
    // 第一引数にgeometry 第二引数にコールバックを定義
    line.setGeometry(linePoints, transformLineMethod);
    const geometry = line.geometry;

    // * ******************************
    // * Create the Line Material
    // dashArray - the length and space between dashes. (0 - no dash)
    // dashRatio - defines the ratio between that is visible or not (0 - more visible, 1 - more invisible).
    // dashOffset - defines the location where the dash will begin. Ideal to animate the line.
    // DashArray: The length of a dash = dashArray * length.
    // Here 2 mean a cash is 2 time longer that the original length

    // 線の長さ
    const dashArray = 2;
    // 0から開始し、破線を表示するためにデクリメントされる(よくわかってない)
    const dashOffset = 0;
    // 線全体の長さのうち表示されているものと表示されていないものの比率。なのでvisibleLengthが表示される線の長さになる。
    const dashRatio = 1 - (visibleLength * 0.5);
    // geometryは線全体で、透明の筒の線のようなものをイメージするとわかりやすい。materialがその中を流れていく設定をしているイメージ
    const material = new MeshLineMaterial({
      lineWidth: width,
      dashArray,
      dashOffset,
      dashRatio,
      opacity,
      transparent: true,
      //オブジェクトが重なり、隠れてしまった面を描画させないようにするかどうか
      depthWrite: false,
      color,
    });

    // * ******************************
    // * Init
    // meshを継承しているので、meshが生成できる。以降、this.positionやthis.material,this.geometoryなど、meshのプロパティが使用できる。
    super(geometry, material);
    // meshでのポジション線全体の位置
    this.position.copy(position);
    // 線の進むスピードを定義
    this.speed = speed;
    this.voidLength = dashArray * dashRatio; // 線の長さ * 表示比率なので表示されている線の長さになる(たぶん)
    this.dashLength = dashArray - this.voidLength; // 残りの線の長さ(たぶん)

    //透明になり始める閾値。
    this.dyingAt = 1;
    //透明になり始めた後、残りの長さまで超えたらラインオブジェクトを消す
    this.diedAt = this.dyingAt + this.dashLength;

    // Bind
    this.update = this.update.bind(this);
  }


  /**
   * * *******************
   * * UPDATE
   * * *******************
   */
  update() {
    // offsetを減らしていき、線を移動させていく
    this.material.uniforms.dashOffset.value -= this.speed;

    // TODO make that into a decorator
    // Reduce the opacity then the dash start to desapear
    // this.material.uniforms.dashOffset.valueのマイナスが大きくなってきたらopacityが引かれていく。
    if (this.isDying()) {
      this.material.uniforms.opacity.value = 0.9 + ((this.material.uniforms.dashOffset.value + 1) / this.dashLength);
    }
  }


  /**
   * * *******************
   * * CONDITIONS
   * * *******************
   */
  isDied() {
    return this.material.uniforms.dashOffset.value < -this.diedAt;
  }

  isDying() {
    return this.material.uniforms.dashOffset.value < -this.dyingAt;
  }
}