<template>
  <h2 class="titleLinelogo is_pc"><router-link :to="{path:'/'}"><img src="@/assets/images/tomonite_linelogo.png" alt="トモニテ 子育て大賞 2023"></router-link></h2>
  <div class="home">
    <section class="mv">
      <div class="container">
        <span><img src="@/assets/images/mv/mv1.png" alt=""></span>
        <span><img src="@/assets/images/mv/mv2.png" alt=""></span>
        <span><img src="@/assets/images/mv/mv3.png" alt=""></span>
        <span><img src="@/assets/images/mv/mv4.png" alt=""></span>
        <span><img src="@/assets/images/mv/mv5.png" alt=""></span>
        <span><img src="@/assets/images/mv/mv6.png" alt=""></span>
        <span><img src="@/assets/images/mv/mv7.png" alt=""></span>
        <span><img src="@/assets/images/mv/mv8.png" alt=""></span>
        <span><img src="@/assets/images/mv/mv9.png" alt=""></span>
        <span><img src="@/assets/images/mv/mv10.png" alt=""></span>
        <span><img src="@/assets/images/mv/mv11.png" alt=""></span>
        <span><img src="@/assets/images/mv/mv12.png" alt=""></span>
        <span><img src="@/assets/images/mv/mv13.png" alt=""></span>
        <span><img src="@/assets/images/mv/mv14.png" alt=""></span>
      </div>
    </section>

    <div class="contents">
      <div class="decolation">
        <div class="imagesContainer">
          <img class="flg" src="@/assets/images/flg.svg" alt="">
          <svg class="trapezoidSvg" width="1920" height="715" viewBox="0 0 1920 715" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_2)">
              <mask id="mask0_1_2" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="1920" height="715">
                <path d="M1920 0H0V714.356H1920V0Z" fill="white"/>
              </mask>
              <g mask="url(#mask0_1_2)">
                <path id="trapezoidSvg" d="M1784.94 273.956C1665.09 263.85 1575.06 243.461 1469.09 203.265C1363.12 163.069 1230.88 108.179 1146.95 0H773.052C689.124 108.179 556.88 163.069 450.913 203.265C344.946 243.461 254.913 263.85 135.056 273.956C74.492 279.063 29.602 274.61 0 268.956V714.356H1920V268.956C1890.4 274.611 1845.51 279.064 1784.94 273.956Z" fill="#FFF9E3"/>
              </g>
            </g>
            <defs>
              <clipPath id="clip0_1_2">
                <rect width="1920" height="714.356" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </div>
        <h2 class="title active">
          <span class="bg_circleContainer"><img class="bg_circle" src="@/assets/images/mv_circle.png" alt=""></span>
          <img class="logo_icon" src="@/assets/images/mv_logo.svg" alt="2023 トモニテ 子育て大賞">
        </h2>
        <section class="intro">
          <h2 class="sectionTitle">ママやパパをはじめ、<br class="is_sp">子育てにかかわる方々が投票。<br/>本当に役立つと思った商品を厳選！</h2>
          <h3 class="subtitle">全32部門169品の中から、<br class="is_sp">各部門で最優秀賞と優秀賞だけを選出！</h3>
          <span class="toScroll">
            <img class="logo_icon" src="@/assets/images/scroll.png" alt="SCROLL">
          </span>
        </section>
      </div>

      <section class="about">
        <h3 class="sectionTitle">
          <img src="@/assets/images/about_title.png" alt="トモニテ 子育て大賞とは?">
        </h3>
        <div class="textContainer">
          <p class="text">子育てをサポートする商品は世の中にあふれているけれど、<br/>どれを選んでいいのかわからない。<br/>そんな方にこそ、安心できる選択肢を。</p>
          <p class="text">昨年までの<span>「MAMADAYS総選挙」</span>は今年、<br/>2023年8月のブランドリニューアルを経て<br class="is_sp"><span>「トモニテ子育て大賞」</span>として開催。</p>
          <p class="text">ファミリー向けメディアで国内No.1*の<br class="is_sp">SNSファン数を抱えるトモニテが、<br class="is_sp">本当に役立つ商品を厳選するコンテスト。</p>
          <p class="text">かけがえのない日々を重ねていくすべての家族と、<br/>いい商品が出会う場所。それがトモニテ子育て大賞です。</p>
          <small>※「SNS」=Instagram、Facebook、YouTube、Twitter、LINE、LINE NEWS、Pinterest、Tiktok。自社調べ（2022年3月1日時点）</small>
        </div>
      </section>

      <section class="awards_categorys">
        <template v-for="(category) in categoryDefinition.categorys" :key="category.id">
          <section class="categoryItem anchorTarget" v-bind:id="category.id" v-bind:class="category.id + 'ForAnchor'">
            <section class="categorys">
              <div class="banner">
                <span class="genre">ジャンル</span>
                <span class="divider">|</span>
                <span class="categoryName">{{ category.name }}</span>
                <span v-if="category.id !== 'tomonite-special-award' && category.id !== 'department-of-expectations'" class="grandPrize">最優秀賞</span>
                <div v-if="category.id !== 'department-of-expectations'" class="link">
                  <router-link :to="{path:'/'+category.id}">
                    <p>受賞商品一覧を見る</p>
                    <span class="allowBg"><span class="circle"></span></span>
                  </router-link>
                </div>
              </div>
              <div v-if="category.id !== 'department-of-expectations'" class="is_sp spLink">
                <router-link :to="{path:'/'+category.id}">
                  <p>受賞商品一覧を見る</p>
                  <span class="allowBg"><span class="circle"></span></span>
                </router-link>
              </div>
              <div v-if="category.id === 'tomonite-special-award'" class="introductionOfTomonite">
                <div class="introduction">
                  <h3 class="title"><img src="@/assets/images/tomonite_special.png" alt="トモニテ 特別賞とは?"></h3>
                  <div class="text"><p>トモニテの理念である「子育てを通じて、人が、社会が、ともに手をとる世界を目指して」に対し、その世界観の実現にふさわしいと考える団体／企業様、人に贈る賞。</p></div>
                </div>
              </div>
              <ul class="items">
                <template v-for="(department) in category.departments" :key="department.id">
                  <template v-for="(award, award_index) in departmentsData[department.id].awards" :key="award.id">
                    <router-link v-bind:class="{ disabled: department.id === 'expectations-award', normal: department.id !== 'expectations-award' }"
                                 :to="{path: department.id !== 'expectations-award' ? '/'+category.id : '', hash: department.id !== 'expectations-award' ? '#'+department.id : ''}"
                                 v-on:click="sendTracking('click', 'tt_el2023', `${department.id}_${award.id}_more`)">
                      <h4 v-if="award_index === 0" class="is_sp departmentNameSp">
                        {{ department.name }}部門
                        <small v-if="department.id === 'expectations-award'" class="annotation is_sp">※トモニテ子育て大賞の実施にご協賛いただいた<br>企業様の商品に贈る賞</small>
                      </h4>
                      <li>
                        <div class="contentsArea">
                          <p class="badge">
                            <img v-bind:src="`/products/${category.id}/${department.id}/g.png`" v-bind:alt="`${department.id}部門 最優秀賞`">
                          </p>
                          <div class="imageArea">
                            <p class="product">
                              <img v-bind:src="`/products/${category.id}/${department.id}/${award.id}.png`" alt="">
                            </p>
                          </div>
                          <div class="infoArea">
                            <p class="is_pc" v-bind:class="[department.id, 'departmentName']">
                              {{ department.name + "部門" }}
                            </p>
                            <small v-if="department.id === 'expectations-award'" class="annotation is_pc">※トモニテ子育て大賞の実施にご協賛いただいた企業様の商品に贈る賞</small>
                            <div class="companyBrand">
                              <p class="companyName" v-html="productsData[award.id].companyName"></p>
                              <p class="brandName" v-html="productsData[award.id].brandName"></p>
                            </div>
                            <div class="productName">
                              <p v-html="productsData[award.id].name"></p>
                              <small v-if="productsData[award.id].annotation"
                                     class="annotation">
                                {{ productsData[award.id].annotation }}
                              </small>
                            </div>
                            <template v-if="department.id !== 'expectations-award'">
                              <div class="awardLabel">
                                <p>受賞理由</p>
                              </div>
                              <p class="awardComment">{{ award.comment }}</p>
                            </template>
                          </div>
                        </div>
                        <div class="border"></div>
                        <div class="linkContainer">
                          <template v-if="department.id !== 'expectations-award'">
                            <div class="moreLink">
                              <p>もっと見る</p>
                              <span class="allowBg"><span class="circle"></span></span>
                            </div>
                          </template>
                          <template v-else>
                            <a class="moreLink"
                               v-bind:href="productsData[award.id].detailPageUrl"
                               v-on:click.stop="sendTracking('click', 'tt_el2023', `${department.id}_${award.id}_detail`)"
                               target="_blank">
                              <p>詳細を見る</p>
                              <span class="allowBg"><span class="circle"></span></span>
                            </a>
                            <br class="is_sp">
                            <a class="moreLink"
                               v-bind:href="productsData[award.id].introductionPageUrl"
                               v-on:click.stop="sendTracking('click', 'tt_el2023', `${department.id}_${award.id}_article`)"
                               target="_blank">
                              <p>記事はこちら</p>
                              <span class="allowBg"><span class="circle"></span></span>
                            </a>
                          </template>
                        </div>
                      </li>
                    </router-link>
                  </template>
                </template>
              </ul>
            </section>
          </section>
        </template>
      </section>
    </div>
    <StickyNav v-bind:menus="categoryDefinition ? categoryDefinition.categorys : []"
               v-bind:isTop="true"
               :key="'home'"/>
  </div>
</template>

<script>
import { TimelineLite } from 'gsap'
import store from '@/store/index'
import util from '@/jsUtil/util'
import StickyNav from '@/components/StickyNav'
export default {
  name: 'Home',

  components: {
    StickyNav,
  },
  
  created: function () {
   //ここが一番最初に遷移してコンポーネントを作成した時(つまりurl直打ちし最初にコンポーネントされる時)の挙動になる。
  },

  data () {
    return {
      events: {},
      categoryDefinition: [],
      departmentsData: null,
      productsData: null,
      unsubscribe: null,
      isSp: false,
    }
  },

  mounted () {
    this.setMetaInfo();
    //subscribeは関数として呼び出すとunsubscribeを返し、購読を中止するようになっている。
    //https://vuex.vuejs.org/api/#subscribe
    this.unsubscribe = store.subscribe((mutation, _) => {
      switch(mutation.type) {
        case "storeCategoryDefinitions":
          if (store.state.categoryDefinition != null) {
            // vuex自体が変更されてしまうのでローカルで保持
            // 逆に全部反映させたい場合はそのまま代入する
            this.categoryDefinition = JSON.parse(JSON.stringify(store.state.categoryDefinition))
          }
          break;
        case "toggleHomeAnimation":
          if( store.state.homeAnimationFlg ) {
            this.homeStartAnimation();
          }
          break;
      }
    });

    this.hiddenNavigation();

    // this.scrollEvents();
    // util.triggerEvent(window,"scroll");

    if (store.state.categoryDefinition != null) {
      // vuex自体が変更されてしまうのでローカルで保持
      // 逆に全部反映させたい場合はそのまま代入する
      this.categoryDefinition = JSON.parse(JSON.stringify(store.state.categoryDefinition));
      this.departmentsData = JSON.parse(JSON.stringify(store.state.departments));
      this.productsData = JSON.parse(JSON.stringify(store.state.products));
    }

    // setTimeout(()=>{
    //   this.scrollEvents();
    // },1000);
   this.isSp = 'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  },

  // メモリリーク回避 https://jp.vuejs.org/v2/cookbook/avoiding-memory-leaks.html
	beforeRouteEnter (to, form, next) {
    const resolver_anim_url = (imgurl) => {
      return new Promise((resolve,reject) => {
        const url = imgurl;
        const img = new Image();
        img.onload = () => resolve(imgurl);
        img.onerror = (e) => reject(e);
        img.src = url;
      });
    }

    const preloadProducts = async () => {
      const categorys = store.state.categoryDefinition.categorys;
      const departmentsData = store.state.departments;
      categorys.forEach( async (category,_)=>{
        const departments =  category.departments;
        departments.forEach( async (department,_)=>{
          const awards = departmentsData[department.id].awards;
          awards.forEach( async (award,_) => {
            const representImagePath = `/products/${category.id}/${department.id}/${award.id}.png`;
            await resolver_anim_url(representImagePath);
          });
        });
      })
    }

    const sleep = (time) => {
      return new Promise((resolve, _) => {
          setTimeout(() => {
              resolve();
          }, time);
      });
    }

    let handleTouchMove = (event) => {
      event.preventDefault();
    }
    
    if (!store.state.isFirstLoad) {
      next();
      document.addEventListener('touchmove', handleTouchMove, { passive: false });
      document.querySelector("body").style.overflow = "hidden";
      preloadProducts().then( async (_) => {
        await sleep(400);
        store.commit("toggleOpen");
        let tl = new TimelineLite({ onComplete: () => {
          tl.kill();
          document.querySelector("body").style.overflow = "auto";
          document.querySelector(".PageAnimation").style.display = "none";
          document.removeEventListener('touchmove', handleTouchMove, { passive: false });
          store.commit("toggleHomeAnimation");
        }});
        tl.delay(0.8);
      });
    } else {
      next();
      document.addEventListener('touchmove', handleTouchMove, { passive: false });
      document.querySelector("body").style.overflow = "hidden";
      store.commit("alreadyLoad");
      preloadProducts().then((_) => {
        const openingEl = document.querySelector(".PageAnimation .opening");
        const scaleEl = document.querySelector(".PageAnimation .opening .scale");
        let tl = new TimelineLite({ onComplete: () => {
          store.commit("toggleOpen");
          let innertl = new TimelineLite({ onComplete: () => {
            document.querySelector("body").style.overflow = "auto";
            document.querySelector(".PageAnimation").style.display = "none";
            document.removeEventListener('touchmove', handleTouchMove, { passive: false });
            store.commit("toggleHomeAnimation");
            innertl.kill(); 
          }});
          innertl.delay(0.8);
          openingEl.parentNode.removeChild(openingEl);
          tl.kill();
        }});
        util.addactive(scaleEl);
        tl.delay(3.2).to(".PageAnimation .opening", 0.6, { opacity: 0});
      });
    }
  },
  
	beforeRouteLeave (to, form, next) {
    let tl = new TimelineLite({ onComplete: () => {
      store.commit("toggleInitOpen");
      setTimeout( () => { 
        store.commit("toggleInitOpen");
        store.commit("toggleOpen") 
      }, 100);
      setTimeout( () => { next(); }, 800);
      tl.kill();
      tl = null;
    }});
    tl.set(".PageAnimation",{ display: "block"});
  },

  beforeUnmount() {
    this.unsubscribe();
    this.unsubscribe = null;
    // window.removeEventListener("scroll", this.events["scrollEvents"]);
  },

  methods: {

    homeStartAnimation() {
      store.commit("toggleHomeAnimation");
    },

    setMetaInfo() {
      const metaTitle = "トモニテ子育て大賞2023"
      const metaDiscription = "ファミリー向けメディアで国内No.1*のSNSファン数を抱えるトモニテが、本当に役立つ商品を厳選するコンテスト。かけがえのない日々を重ねていくすべての家族と、いい商品が出会う場所。それがトモニテ子育て大賞です。"
      const metaUrl = "https://election2023.tomonite.com/"
      document.title = metaTitle;
      document.querySelector("meta[name='description']").setAttribute('content', metaDiscription);
      const headData = Array.from(document.head.children);
      headData.forEach((metaData,_) => {
        if (metaData.getAttribute('property') === "og:title") {
          metaData.setAttribute('content', metaTitle);
        }
        if (metaData.getAttribute('property') === "og:description") {
          metaData.setAttribute('content', metaDiscription);
        }
        if (metaData.getAttribute('property') === "og:url") {
          metaData.setAttribute('content', metaUrl);
        }
      });
    },

    hiddenNavigation() {
      const menuEl = document.querySelector(".menu");
      const navEl = document.querySelector("#navigation");
      const elbody = document.querySelector("body");
      if(menuEl.classList.contains("active")) {
          elbody.style.overflow = "visible";
          util.addactive(navEl, "deactive");
          util.removeactive(menuEl);
          util.removeactive(navEl);
          setTimeout(()=>{
            navEl.style.display = "none";
          },800);
      }
    },

    sendTracking(action,category,label){
      const tagExclusionLabel = label.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'');
      this.$gtag.event(action, {
        'event_category' : category,
        'event_label' : tagExclusionLabel
      });
    },

  }
}
</script>

<style lang="scss">
@import "./scss/home.scss";
@import "./scss/home_sp.scss";
</style>
