import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store/index'
import util from '@/jsUtil/util'

const sleep = (time) => {
  return new Promise((resolve, reject) => {
      setTimeout(() => {
          resolve();
      }, time);
  });
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter(to, from, next) {
      async function checkData() {
        const retryArr = Array.from({length: 300}, (v, k) => k);
        for(let i of retryArr){
          if (store.state.categoryDefinition != null) {
            return Promise.resolve(true);
          }
          await sleep(300);
        }
      }
      checkData().then((res) => {
        next();
      });
    }
  },
  {
    path: '/:id',
    name: 'Categorys',
    component: () => import(/* webpackChunkName: "categorys" */ '../views/Categorys.vue'),
    beforeEnter(to, from, next) {
      async function checkData() {
        const retryArr = Array.from({length: 300}, (v, k) => k);
        for(let i of retryArr){
          if (store.state.categoryDefinition !== null && store.state.categoryDefinition.categorys !== undefined) {
            return Promise.resolve(true);
          }
          await sleep(300);
        }
      }
      checkData().then((res) => {
        if (store.state.categoryDefinition !== null && store.state.categoryDefinition.categorys !== undefined ) {
          const target = store.state.categoryDefinition.categorys.filter( category => category.id == to.params.id );
          if(target.length && to.params.id !== "department-of-expectations") {
            next();
          } else {
            next({path: '/'});
          }
        } else {
          next({path: '/'});
        }
      });
    }
  },
  { 
    path: "/:id(.*)/", 
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  scrollBehavior: async (to, from, savedPosition) => {
    const findEl = async (hash, x) => {
      return document.querySelector(hash) ||
          new Promise((resolve, reject) => {
              if (x > 50) {
                  return resolve();
              }
              setTimeout(() => {
                  resolve(findEl(hash, ++x || 1));
              }, 100);
          });
    }
    if(to.hash) {
      const el = await findEl(to.hash);
      setTimeout(()=>{
        const elOffsetTop = util.getoffsetTop(el) - 100;
        return window.scrollTo(0, elOffsetTop);
      },500);
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({left: 0, top: 0});
        },300);
      })
    }
  }
})

export default router
